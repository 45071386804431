import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout
      heroTitle="The Ether Family Presents..."
      heroSubTitle="Ego-tripping Indie-Rock"
      heroBackgroundImage="/images/theetherfamilypresents-how-to-get-lost-banner.jpg"
    >
      <SEO
        title="The Ether Family Presents... Ego-tripping Indie-Rock"
        ogDescription="The Ether Family Presetns... is an indie-pop/garage-rock band from Atlanta, GA."
        image="https://theetherfamilypresents.com/images/theetherfamilypresents-pressshot-2010.jpg"
        url="https://theetherfamilypresents.com"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Things started back in 1992...</h2>
            <p>
              <a href="https://jimmyether.com">Jimmy Ether</a> and Earl Ether started a band called Spiral in 1992 (later changing its name to Suburban Reality Club - abbreviated subReal). As that band ended in 1997, they began writing what became the Tools For Men Who Want To Succeed album with Gayle Ether guesting on a couple of songs.
            </p>
            <p>
              Once the Tools record was finished, they began building a rock record of four sonically distinct album movements based around seasons, modes of travel, greek gods, and other weird shit. It was called How To Get Lost In A Time-Consuming Ego Trip. This project would get shelved for a bit though as the opportunity to become a real band (not just a studio project) emerged.
            </p>
            <p>
              Gayle Ether officially joined on bass and vocals and Freddy Ether on drums. The band cut its teeth writing and recording its debut full-band release Original Motion Picture Soundtrack.
            </p>
            <p>
              When the band returned to How to Get Lost, the album concept had broadened. What was once a single four-part album has now become a four-album 80-song cycle.
            </p>
            <p>
              On the whole, The Ether Family Presents... aims to capture a live recording performance esthetic reminiscent of the late 60’s and early 70’s while highlighting the unique personality and musical chemistry of the band. 
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/theetherfamilypresents-pressshot-2010.jpg" alt="The Ether Family Presents... Atlanta, GA" />
          </div>
        }
      />
      <PageWrapper color="olive">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/the-ether-family-presents-and-vice-versa.jpg" alt="The Ether Family Presents... ...and vice versa" />
              <p className="small">Illustrations by Robert Gonzales.</p>
            </div>
          }
          textRight={
            <div>
              <h3>...and vice versa</h3>
              <p>
                This started life as a 2010 RPM Challenge where you write, record, mix, and release an album all in the month of February. Some good stuff came out of those 28 days. Some weird and goofy stuff came out of those 28 days. We may have gotten a little punch drunk.
              </p>
              <p>
                This is getting the remix and remaster treatment currently. It'll be out on all the cool download and streaming platforms as soon as we're done.
              </p>
              <h4>Download</h4>
              <p>
                Coming Soon!
              </p>
              <h4>Stream</h4>
              <p>
                Coming Soon!
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="silver">
        <SingleColumnBlock
          textPadded
          centered
          text={
            <div>
              <h2>How To Get Lost In A Time-Consuming Ego Trip Parts 1 through 4</h2>
              <p>
                The Ether Family Presents… has been developing the songs and multi-layered concept for the group’s four-album series, How to Get Lost in a Time-Consuming Ego Trip, for several years (decades?!). Part 3 and 4 are in the works now. Part 1 and 2 are complete, but getting the re-issue treatment.
              </p>
            </div>
          }
        />
      </PageWrapper>
      {/*<PageWrapper color="water">
        <SingleColumnBlock
          centered
          text={
            <div>
              <h3>How To Get Lost In A Time-Consuming Ego Trip - Part 4 of 4</h3>
              <h4>Spring ~ Water ~ Persephone ~ Boat</h4>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/big-ragoo-fragrent-fowl.jpg" alt="Fragrant Fowl! by Big Ragoo" />
            </div>
          }
          textRight={
            <div>
              <p>

              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://theetherfamilypresents.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Coming soon.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="fire">
        <SingleColumnBlock
          centered
          text={
            <div>
              <h3>How To Get Lost In A Time-Consuming Ego Trip - Part 3 of 4</h3>
              <h4>Winter ~ Fire ~ Hades ~ Buried/Trapped</h4>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/big-ragoo-fragrent-fowl.jpg" alt="Fragrant Fowl! by Big Ragoo" />
            </div>
          }
          textRight={
            <div>
              <p>

              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://theetherfamilypresents.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Coming soon.
              </p>
            </div>
          }
        />
        </PageWrapper>*/}
      <PageWrapper color="air">
        <SingleColumnBlock
          centered
          text={
            <div>
              <h3>How To Get Lost In A Time-Consuming Ego Trip - Part 2 of 4</h3>
              <h4>Fall ~ Air ~ Zeus ~ Flight</h4>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/how-to-get-lost-in-a-time-consuming-ego-trip-2-of-4.jpg" alt="How To Get Lost In A Time-Consuming Ego Trip - Part 2 of 4" />
              <p className="small">Illustrations by Jason Albin Thomas of <a href="https://redrocketfarm.com/" target="_blank">Red Rocket Farm</a>.</p>
            </div>
          }
          textRight={
            <div>
              <p>

              </p>
              <p>
                This is getting the remix and remaster treatment currently. It'll be out on all the cool download and streaming platforms as soon as we're done.
              </p>
              <h4>Download</h4>
              <p>
                Coming Soon!
              </p>
              <h4>Stream</h4>
              <p>
                Coming Soon!
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="earth">
        <SingleColumnBlock
          centered
          text={
            <div>
              <h3>How To Get Lost In A Time-Consuming Ego Trip - Part 1 of 4</h3>
              <h4>Summer ~ Earth ~ Hera ~ Automobile</h4>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/how-to-get-lost-in-a-time-consuming-ego-trip-1-of-4.jpg" alt="How To Get Lost In A Time-Consuming Ego Trip - Part 2 of 4" />
              <p className="small">Illustrations by Jason Albin Thomas of <a href="https://redrocketfarm.com/" target="_blank">Red Rocket Farm</a>.</p>
            </div>
          }
          textRight={
            <div>
              <p>

              </p>
              <p>
                This is getting the remix and remaster treatment currently. It'll be out on all the cool download and streaming platforms as soon as we're done.
              </p>
              <h4>Download</h4>
              <p>
                Coming Soon!
              </p>
              <h4>Stream</h4>
              <p>
                Coming Soon!
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="soundtrack">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/original-motion-picture-soundtrack.jpg" alt="The Ether Family Presents... Original Motion Picture Soundtrack" />
            </div>
          }
          textRight={
            <div>
              <h3>Original Motion Picture Soundtrack</h3>
              <p>
                Recorded at Channel One Studios, Atlanta GA in 1999.
              </p>
              <p>
                This is getting the remix and remaster treatment currently. It'll be out on all the cool download and streaming platforms as soon as we're done.
              </p>
              <h4>Download</h4>
              <p>
                Coming Soon!
              </p>
              <h4>Stream</h4>
              <p>
                Coming Soon!
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="tools">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/tools-for-men-who-want-to-succeed.jpg" alt="The Ether Family Presents... Tools For Men Who Want To Succeed" />
            </div>
          }
          textRight={
            <div>
              <h3>Tools For Men Who Want To Succeed</h3>
              <p>

              </p>
              <p>
                This is getting the remix and remaster treatment currently. It'll be out on all the cool download and streaming platforms as soon as we're done.
              </p>
              <h4>Download</h4>
              <p>
                Coming Soon!
              </p>
              <h4>Stream</h4>
              <p>
                Coming Soon!
              </p>
            </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/jimmy-ether.jpg" alt="Jimmy Ether of The Ether Family Presents..." />
          </div>
        }
        textRight={
          <div>
            <h3>Jimmy Ether</h3>
            <h4>Vocals and Guitar (Drums on recent stuff)</h4>
            <p>
              Jimmy plays in a few other bands these days but is carving out time to wrap up the How To Get Lost series and reissue all the records in glorious digital something or other.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Gayle Ether</h3>
            <h4>Bass and Vocals</h4>
            <p>
              Gayle is not keen on hitting the stage ever again, but she's still weaving bass lines and vocal harmonies in the studio.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/gayle-ether.jpg" alt="Gayle Ether of The Ether Family Presents..." />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/earl-ether.jpg" alt="Earl Ether of The Ether Family Presents..." />
          </div>
        }
        textRight={
          <div>
        <h3>Earl Ether</h3>
        <h4>Guitar and Vocals</h4>
        <p>
          Earl is experimenting with alternate tunings, making crazy things with wood, and raising cats.
        </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Freddy Ether</h3>
            <h4>Drums and Vocals</h4>
            <p>
              Freddy joined the band in 1999 and endured with us for over a decade. He stepped away in 2010 for personal reasons, but who knows? Life is crazy. We may see him back.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/freddy-ether.jpg" alt="Freddy Ether of The Ether Family Presents..." />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Give us a holler</h2>
            <RequestMoreInfo />
          </div>
        }
        textRight={
          <div>
            <img src="/images/theetherfamilypresents-pressshot-1999.jpg" alt="The Ether Family Presents... at the airport" />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
